import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { WalletModalProvider } from "./context/walletModalContext";
const getLibrary = (provider) => new Web3(provider);
ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <WalletModalProvider>
      <App />
    </WalletModalProvider>
  </Web3ReactProvider>,
  document.getElementById("root")
);
