/* eslint-disable no-unused-vars */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components";
import Home from "./pages/Home";
import Reward from "./pages/Rewards";
import LeaderBoard from "./pages/LeaderBoard";
import Swap from "./pages/Swap";

import "./App.scss";
import Details from "./pages/Details";
import Stake from "./pages/Stake/Stake";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Toaster } from "react-hot-toast";
import Inject from "./pages/Inject";

function App() {
  return (
    <div className="App">
      <div className="relative">
        <BrowserRouter>
          <Toaster position="top-center" />
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="rewards" element={<Reward />} />
              {/* <Route path="leaderboard" element={<LeaderBoard />} />
              <Route path="swap" element={<Swap />} /> */}
              {/* <Route path="details/:id/:type" element={<Details />} /> */}
              <Route path="inject-reward-token" element={<Inject />} />

              <Route path="stake/:id" element={<Stake />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
