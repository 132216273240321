/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { InformationModal, Title } from "../../components";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
// import Slogo from "../../assets/images/shkooby_icon.png";
// import SElogo from "../../assets/images/shkoobyEth_icon.png";
import { MiniButton } from "../../components/button";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { formatFromWei, getContract } from "../../web3/helpers";
import RewardRow from "./RewardRow";
// import InformationModal from "../components/modals/InformationModal";
// import ConfirmationModal from "../components/modals/ConfirmationModal";
const reviewData = [
  {
    img: "./fav.png",
    name: "Flexi Pool",
    amount: "1030 FIREFOX",
    reward: "5.2 FIREFOX",
    timer: "23 Nov 2323 12.00",
  },
  {
    img: "./fav.png",
    name: "Flexi Pool",
    amount: "$0.00",
    reward: "4.3 FIREFOX",
    timer: "23 Nov 2323 12.00",
  },
  {
    img: "./fav.png",
    name: "Locked Pool (1 to 6 Months)",
    amount: "$5.78",
    reward: "2.1 FIREFOX",
    timer: "23 Nov 2323 12.00",
  },
  {
    img: "./fav.png",
    name: "Flexi Pool",
    amount: "$74.76",
    reward: "3.4 FIREFOX",
    timer: "23 Nov 2323 12.00",
  },
];
dayjs.extend(utc);
// const date = dayjs.utc(1318781876 * 100).format();

const Reward = () => {
  const [totalClaimedBalance, setTotalClaimedBalance] = useState(0);
  const { account, library } = useWeb3React();
  const [stakeIds, setStakeIds] = useState([]);
  const [stakesDetails, setStakesDetails] = useState([]);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [totalStakedBalance, setTotalStakedBalance] = useState(0);
  useEffect(() => {
    const getData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library);
        if (!ok) {
          return;
        }
        try {
          const userStakeDetails = await contract.methods
            .getUserAllStakeDetails()
            .call({ from: account });
          console.log(userStakeDetails);
          setStakesDetails(userStakeDetails);
          // const _stakeIds = await contract.methods
          //   .getUserStakeIds()
          //   .call({ from: account });
          // setStakeIds(_stakeIds);
          const totalClaimedBalance = await contract.methods
            .getUserDetails(account)
            .call();
          console.log(totalClaimedBalance);
          setTotalClaimedBalance(formatFromWei(totalClaimedBalance[2], 9));
          setTotalStakedBalance(
            formatFromWei(totalClaimedBalance.totalStakedBalance, 9)
          );
          const amountStaked = await contract.methods
            .totalStakedBalance()
            .call();
          const convertedStakedValue = formatFromWei(amountStaked, 9);
          setTotalStaked(convertedStakedValue);
          const amountClaimed = await contract.methods
            .totalClaimedBalance()
            .call();
          const convertedAmountClamied = formatFromWei(amountClaimed, 9);
          setTotalClaimed(convertedAmountClamied);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getData();
  }, [account, library]);

  const head = [
    "Duration",
    "Status",
    "Staked",
    "Rewards",
    "Claimable Rewards",
    // "Next Claim Time",
    "Claim Time",
    "Action",
  ];

  return (
    <div>
      <div className=" bg-transparent  border-gray-500 border rounded-lg py-10">
        <Title title="Rewards" desc="" />
        <p className="font-semibold  text-base mt-2 text-gray md:mx-20 text-center  xl:mx-72 ">
          Stake $FBURN in one of our two core pools to start earning rewards
          with Flexi and variable locking pools explained below:
        </p>
        <p className="font-normal  text-base text-center mt-2 text-gray md:mx-20  xl:mx-72 ">
          FBURN Flexi Pool allows you to redeem your staked assets at any time.
        </p>
        <p className="font-normal  text-base text-center mt-2 text-gray md:mx-20  xl:mx-72 ">
          FBURN Locked Pool allows you to staking your assets for a specific
          time frame. Compared to Flexible Staking, Locked Staking provides
          heigher interests but requires you to lock your assets for the
          displayed duration. You can still redeem your assets at any time you
          wish before the end of the locked duration, but you won't receive any
          interest generated over your staking period
        </p>
        <div className="  bg-transparent  border-gray-500 border  mx-14 py-10 px-6 mt-10  rounded-lg  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          <div className=" text-center mx-auto">
            <p className=" font-semibold text-xl md:text-2xl text-primary uppercase">
              My Staked Balance
            </p>
            <p className="font-bold text-xl md:text-3xl mt-4">
              {totalStakedBalance} FBURN
            </p>
          </div>

          <div className=" text-center mx-auto">
            <p className=" font-semibold text-xl md:text-2xl text-primary uppercase">
              My Claimed Rewards
            </p>
            <p className="font-bold text-xl md:text-3xl mt-4">
              {totalClaimedBalance} FBURN
            </p>
          </div>

          <div className=" text-center mx-auto">
            <p className=" font-semibold text-xl md:text-2xl text-primary uppercase">
              Total Staked
            </p>
            <p className="font-bold text-xl md:text-3xl mt-4">
              {totalStaked} FBURN
            </p>
          </div>
          <div className=" text-center mx-auto">
            <p className=" font-semibold text-xl md:text-2xl text-primary uppercase">
              Total Claimed
            </p>
            <p className="font-bold text-xl md:text-3xl mt-4">
              {totalClaimed} FBURN
            </p>
          </div>
        </div>
      </div>
      <div className="mt-14">
        <Title title="Stakes & Rewards" />
      </div>

      <div className="overflow-x-auto ">
        <div className="mt-20" style={{ width: 1288 }}>
          <table className="w-full table-auto border-spacing-2 min-w-[900px]">
            <thead>
              <tr>
                {head.map((val, i) => (
                  <th key={i} className={`px-6 py-4 uppercase text-sm`}>
                    {val}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {stakesDetails.length > 0 ? (
                stakesDetails.map((val, i) => (
                  <React.Fragment key={i}>
                    <RewardRow val={val} />
                  </React.Fragment>
                ))
              ) : (
                <tr className=" text-sm mt-4">
                  <td
                    colSpan={head.length}
                    className=" px-6 py-10  text-center bg-white bg-opacity-25 "
                  >
                    Stakes Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Reward;
