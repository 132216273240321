import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import LockedWarningModal from "../../components/LokedWarningModal";
import Timer from "../../components/Timer";
import {
  claim,
  formatFromWei,
  getContract,
  reStake,
  unStake,
} from "../../web3/helpers";
// import { formatFromWei } from "../../utils";

const RewardRow = ({ val }) => {
  const [unclaimedBalance, setUnclaimedBalance] = useState(0);
  const { library, account } = useWeb3React();
  const [open, setOpen] = useState(false);
  // const [details, setDetails] = useState([[], []]);
  //   const createdTime = dayjs.utc(val.createdTime * 1000).format("YYYY-MM-DD");
  const tdClass = "px-6 py-4 text-center bg-white bg-opacity-25 ";
  // console.log(val);
  useEffect(() => {
    const getData = async () => {
      const { ok, contract } = await getContract(library);
      if (!ok) {
        return;
      }
      try {
        const unclaimedBalance = await contract.methods
          .getUnclaimedRewards(val.id)
          .call({ from: account });

        setUnclaimedBalance(formatFromWei(unclaimedBalance, 9));
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [account, library, val]);

  // const isAbletoClaim = Number(val.nextClaimTime) * 1000 - Date.now();
  let isAbletoUnstake = Number(val.unlockTime) * 1000 - Date.now();
  isAbletoUnstake = isAbletoUnstake < 0;
  // console.log("IsAble to Claim", isAbletoClaim);
  console.log(unclaimedBalance);
  const handleUnstake = () => {
    unStake(library, val.id);
    setOpen(false);
  };
  return (
    <>
      <tr className="  text-sm">
        <td className={tdClass}>
          <p> {Number(val.lockDuration) === 1 ? 0 : val.lockDuration} Days</p>
        </td>
        <td className={tdClass}>
          <p className="text-sm font-bold">
            {Number(val.status) === 1 ? (
              <span className="text-white  block px-2 py-1.5 bg-green-700  rounded-full">
                Active
              </span>
            ) : (
              <span className="text-white block px-2 py-1.5 bg-red-700  rounded-full">
                Unstaked
              </span>
            )}
          </p>
        </td>
        <td className={tdClass}>
          <p> {formatFromWei(val.stakeBalance, 9)} FBURN</p>
        </td>
        <td className={tdClass}>
          <p>{formatFromWei(val.totalClaimedRewards, 9)} FBURN</p>
        </td>
        <td className={tdClass}>
          <p> {Number(val.status) === 1 ? unclaimedBalance : 0} FBURN</p>
        </td>
        {/* <td className={tdClass}>
          <p>
            {val.nextClaimTime > 0 ? (
              Number(val.status) === 1 ? (
                <Timer timestamp={val.nextClaimTime} />
              ) : (
                "---"
              )
            ) : (
              "---"
            )}
          </p>
        </td> */}
        <td className={tdClass}>
          <p>
            {Number(val.status) === 1 ? (
              <Timer timestamp={val.unlockTime} />
            ) : (
              "---"
            )}
          </p>
        </td>
        <td className={tdClass}>
          <div className="grid grid-flow-col justify-end gap-2">
            <button
              className={`${
                !isAbletoUnstake || Number(val.status) === 0
                  ? " bg-gray-500 pointer-events-none  "
                  : "  bg-primary text-white font-bold"
              }  py-2 px-4 rounded-md`}
              onClick={() => {
                // // return toast.error("Launching Soon!");
                if (!isAbletoUnstake) {
                  return toast.error(" You can`t claim before unlock time.");
                }
                claim(library, val.id);
              }}
            >
              Claim
            </button>
            <button
              className={`${
                Number(val.status) === 0
                  ? " bg-gray-500 pointer-events-none  "
                  : " bg-primary text-white font-bold"
              }  py-2 px-4 rounded-md`}
              onClick={() => {
                if (Number(val.lockDuration) > 1) {
                  return setOpen(true);
                }
                unStake(library, val.id);
              }}
            >
              Unstake
            </button>
            {Number(val.status) === 1 &&
              Number(val.lockDuration) > 1 &&
              isAbletoUnstake && (
                <button
                  className={`${
                    !isAbletoUnstake || Number(val.status) === 0
                      ? " bg-gray-500 pointer-events-none  "
                      : " bg-primary text-white font-bold"
                  }  py-2 px-4 rounded-md`}
                  onClick={() => {
                    reStake(library, val.id);
                  }}
                >
                  ReStake
                </button>
              )}
          </div>
        </td>
      </tr>
      <br />
      <LockedWarningModal
        open={open}
        setOpen={setOpen}
        handleUnstake={handleUnstake}
      />
    </>
  );
};

export default RewardRow;
