// /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { Title } from "../../components";
import { LightButton, PrimaryBtn, SecondaryBtn } from "../../components/button";
import axios from "axios";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import Web3 from "web3";
import utc from "dayjs/plugin/utc";
import NftImg from "../../assets/images/Rectangle 40.png";

import Slider from "react-slick";

import {
  WalletModalContext,
  WalletModalProvider,
} from "../../context/walletModalContext";
import { useWeb3React } from "@web3-react/core";
import { formatFromWei, getContract } from "../../web3/helpers";
var offersSetting = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

dayjs().format();
const Overview = () => {
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [userStakedBalance, setUserStakedBalance] = useState(0);
  const [userUnClaimedBalance, setUserUnClaimedBalance] = useState(0);
  const [totalNfts, setTotalNfts] = useState([]);
  const [holdingNfts, setHoldingNfts] = useState([]);
  // const [unclaimedRewards, setUnClaimedRewards] = useState([]);
  const { connectHandler } = useContext(WalletModalContext);
  const { library, account } = useWeb3React();

  useEffect(() => {
    const getStakeData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library);
        if (!ok) {
          return;
        }
        try {
          const nfts = await contract.methods.getNFTs().call({ from: account });
          // console.log("NFTS", nfts);
          setTotalNfts(nfts);

          const holdingNfts = await contract.methods
            .getHoldingNFTRarities()
            .call({ from: account });
          // console.log("HoldingNFTRarities", holdingNfts);
          setHoldingNfts(holdingNfts);
          const amountStaked = await contract.methods
            .totalStakedBalance()
            .call();
          const convertedStakedValue = formatFromWei(amountStaked, 9);
          setTotalStaked(convertedStakedValue);
          const amountClaimed = await contract.methods
            .totalClaimedBalance()
            .call();
          const convertedAmountClamied = formatFromWei(amountClaimed, 9);
          setTotalClaimed(convertedAmountClamied);
          // const _unclaimedRewards = await contract.methods
          //   .getTotalUnclaimedRewards()
          //   .call();
          // const convertedunClaimedRewards =
          //   Web3.utils.fromWei(_unclaimedRewards);
          // setUnClaimedRewards(convertedunClaimedRewards);

          const userDetails = await contract.methods
            .getUserDetails(account)
            .call();
          console.log(userDetails);
          setUserStakedBalance(
            formatFromWei(userDetails.totalStakedBalance, 9)
          );
          setUserUnClaimedBalance(
            formatFromWei(userDetails.totalClaimedRewards, 9)
          );
          // console.log("amount", amountClaimed);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getStakeData();
  }, [account, library]);

  // dayjs.extend(utc);
  // const date = dayjs.utc(1318781876 * 100).format();

  return (
    <div>
      <div className="bg-transparent  border-gray-500 border rounded-lg py-10 px-4">
        <Title desc="Stake $FBURN  in one of our two core pools to start earning rewards." />
        <div className="grid grid-cols-1 gap-10 sm:gap-0 sm:grid-cols-2 md:mx-20  xl:mx-60 mt-10">
          <OverviewContent
            price={`FBURN  ${userStakedBalance}`}
            account={account}
            loadWeb3={connectHandler}
          />
          <OverviewContent
            title="YOUR UNCLAIMED REWARDS"
            price={`FBURN  ${userUnClaimedBalance}`}
            btn="secondary"
            btnText="Claim"
            disable={true}
            account={account}
            loadWeb3={connectHandler}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-20 gap-6">
        <div className="row-span-2 bg-transparent  border-gray-500 border rounded-lg p-4 md:p-8">
          <div className="flex flex-col">
            <h6 className="font-semibold md:text-2xl mb-4 text-center ">
              NFTs Owned [{totalNfts.length}]
            </h6>
            <div className=" text-center items-center">
              {/* <div className="left flex items-center justify-center">
                <div className=" wrapper flex aic">
                  <Slider {...offersSetting}>
                    {totalNfts.length > 0
                      ? totalNfts.map((item, index) => (
                          <div key={index}>
                            <div className="card flex aic">
                              <img
                                src={`https://defi.mobiwebsolutionz.com/api/lunacats/images/${item}.png`}
                                className="img"
                                alt=""
                              />
                            </div>
                          </div>
                        ))
                      : [1, 2, 3].map((val, i) => (
                          <div key={val}>
                            <div className="card flex aic">
                              <img
                                src={`http://placehold.jp/e74f42/ffffff/300x300.png?text=Your%20NFT%20will%20appear%20here%20%0A&css=%7B%22font-size%22%3A%2216px%22%2C%22font-weight%22%3A%22bold%22%7D`}
                                className="img"
                                alt=""
                              />
                            </div>
                          </div>
                        ))}
                  </Slider>
                </div>
              </div> */}
              <div className=" text-center w-full mt-6">
                <div className="item flex justify-center aic">
                  <div className="item-numb text-3xl font-extrabold mr-3">
                    {holdingNfts[0]}
                  </div>
                  <div className="tag text-3xl font-extrabold">Legendary </div>
                </div>
                <div className="item flex justify-center aic">
                  <div className="item-numb text-3xl font-extrabold mr-3">
                    {holdingNfts[1]}
                  </div>
                  <div className="tag text-3xl font-extrabold">Rare</div>
                </div>
                <div className="item flex justify-center aic">
                  <div className="item-numb text-3xl font-extrabold mr-3">
                    {holdingNfts[2]}
                  </div>
                  <div className="tag text-3xl font-extrabold">Uncommon</div>
                </div>
                <div className="item flex justify-center aic">
                  <div className="item-numb text-3xl font-extrabold mr-3">
                    {holdingNfts[3]}
                  </div>
                  <div className="tag text-3xl font-extrabold">Common</div>
                </div>
              </div>
            </div>
            {!account && (
              <button
                className="bg-primary py-2 px-2 block max-w-max mx-auto mt-4 rounded-md"
                onClick={connectHandler}
              >
                Connect Wallet
              </button>
            )}
          </div>
        </div>
        <div className="p-8 bg-transparent  border-gray-500 border rounded-lg text-center  grid items-center justify-center">
          <h6 className="font-semibold md:text-2xl mb-4">
            Total Amount Staked
          </h6>
          <LightButton type="primary">{totalStaked} FBURN </LightButton>
        </div>
        <div className="p-8 bg-transparent  border-gray-500 border rounded-lg text-center grid items-center justify-center">
          <h6 className="font-semibold text-2xl mb-4">Total Amount Claimed</h6>
          <LightButton type="secondary">{totalClaimed} FBURN </LightButton>
        </div>
      </div>
    </div>
  );
};

export default Overview;

const OverviewContent = ({
  title = "YOUR STAKED BALANCE",
  price = "FBURN  2,42,05,434",
  btn = "primary",
  btnText = "Stake",
  account,
  loadWeb3,
}) => (
  <div className=" text-center">
    <h6 className="font-semibold text-primary text-xl md:text-2xl">{title}</h6>
    <p className="font-semibold text-sm md:text-base my-6">{price}</p>

    {btn === "primary" ? (
      <>
        {account ? (
          <a href="#stack">
            <PrimaryBtn>{btnText}</PrimaryBtn>
          </a>
        ) : (
          <button
            onClick={loadWeb3}
            className="bg-primary py-2 px-6 rounded-md font-medium"
          >
            {btnText}
          </button>
        )}
      </>
    ) : account ? (
      <SecondaryBtn disable>{btnText}</SecondaryBtn>
    ) : (
      <button
        onClick={loadWeb3}
        className="bg-primary py-2 px-6 rounded-md font-medium"
      >
        {btnText}
      </button>
    )}
  </div>
);
