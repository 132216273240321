import Slogo from "./assets/images/shkooby_icon.png";
import SElogo from "./assets/images/shkoobyEth_icon.png";
export const menuList = [
  {
    name: "Overview",
    to: "/",
  },
  // {
  //   name: "Add Stake",
  //   to: "stake",
  // },
  {
    name: "Rewards",
    to: "rewards",
  },
  // {
  //   name: "LUNACats Swap",
  //   to: "/Swap",
  //   // to: "swap",
  // },
  // {
  //   name: "Mint NFT",
  //   to: "/mint-nft",
  //   // to: "leaderboard",
  // },
];

export const poolsData = [
  {
    img: Slogo,
    title: "LUNA CAT 6 Months Pool",
    btn: [
      {
        type: "outline",
        content: "Details",
      },
      {
        type: "filled",
        content: "Stake",
      },
    ],
  },
  {
    img: Slogo,
    title: "LUNA CAT 12 Months Pool",
    btn: [
      {
        type: "outline",
        content: "Details",
      },
      {
        type: "filled",
        content: "Stake",
      },
    ],
  },
];
export const deposisData = [
  {
    img: SElogo,
    name: "LUNA CAT/ETH Uniswap LP",
    amount: "$56438.87847.09",
    lockDate: "17/09/2021",
    unLockDate: "17/09/2021",
  },
  {
    img: SElogo,
    name: "LUNA CAT/ETH Uniswap LP",
    amount: "$56438.87847.09",
    lockDate: "17/09/2021",
    unLockDate: "17/09/2021",
  },
  {
    img: SElogo,
    name: "LUNA CAT/ETH Uniswap LP",
    amount: "$56438.87847.09",
    lockDate: "17/09/2021",
    unLockDate: "17/09/2021",
  },
  {
    img: SElogo,
    name: "LUNA CAT/ETH Uniswap LP",
    amount: "$56438.87847.09",
    lockDate: "17/09/2021",
    unLockDate: "17/09/2021",
  },
];
