import StackInput from "./StackInput";

const Flexible = ({
  handler,
  balance,
  stackValue,
  inputHandler,
  approved,
  poolData,
  setStackValue,
  raritesToApply,
}) => {
  const aprList = [300, 450, 900, 1500];
  // console.log(poolData.id);
  return (
    <>
      <div className="flex justify-between items-center mb-4 md:mb-6 mt-4">
        <p>
          Lock For: <span className="font-medium">{0} Days</span>
        </p>
        <p>
          Rarity Applied :{" "}
          <span className="font-medium">
            {Number(raritesToApply) === 1
              ? "Common"
              : Number(raritesToApply) === 2
              ? "Uncommon"
              : Number(raritesToApply) === 3
              ? "Rare"
              : Number(raritesToApply) === 4
              ? "Legendary"
              : "None"}
          </span>
        </p>
      </div>
      <p className="mt-8 mb-4 font-semibold  text-sm md:text-lg">
        Balance: {Number(balance).toFixed(2)} FBURN
      </p>
      <StackInput
        stackValue={stackValue}
        inputHandler={inputHandler}
        balance={balance}
        setStackValue={setStackValue}
      />
      <p className="text-left text-base my-4">
        Est. APR:{" "}
        {aprList[Number(raritesToApply) ? Number(raritesToApply) - 1 : 0]} %
      </p>
      {/* <div className="p-2 md:py-4  px-4 bg-dd3 text-red rounded-xl text-base font-bold">
        <p className="md:mx-10 text-center leading-3">
          Be aware that there are always risks associated with staking
          contracts. You assume all responsibility.
        </p>
      </div> */}
      <div className="mt-10 primary-btn text-center">
        <button
          className="bg-primary font-bold text-xl py-2 px-8 w-full rounded-full"
          onClick={() => handler(poolData.id)}
        >
          {approved ? "Stake" : "Approve"}
        </button>
      </div>
    </>
  );
};

export default Flexible;
