import { Overview, Pools } from "../sections/homepageSections";

const Home = ({
  account,
  contract,
  tokenContract,
  poolIds,
  setStackOpen,
  stakeOpen,
  stackDetailOpen,
  setStackDetailOpen,
  loadWeb3,
}) => {
  return (
    <div>
      <div className="container">
        <Overview
          setStackOpen={setStackOpen}
          stakeOpen={stakeOpen}
          contract={contract}
          tokenContract={tokenContract}
          account={account}
          poolIds={poolIds}
          setStackDetailOpen={setStackDetailOpen}
          stackDetailOpen={stackDetailOpen}
          loadWeb3={loadWeb3}
        />
        <Pools
          contract={contract}
          tokenContract={tokenContract}
          account={account}
          poolIds={poolIds}
          setStackOpen={setStackOpen}
          stakeOpen={stakeOpen}
          setStackDetailOpen={setStackDetailOpen}
          stackDetailOpen={stackDetailOpen}
          loadWeb3={loadWeb3}
        />
        {/* <Deposits /> */}
      </div>
    </div>
  );
};

export default Home;
