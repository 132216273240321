import { useEffect, useState } from "react";
import "rc-slider/assets/index.css";
import Web3 from "web3";
import { contractAddress } from "../../web3/contracts/primary";
import Flexible from "./components/Flexible";
import Locked from "./components/Locked";
import { useWeb3React } from "@web3-react/core";
import {
  approveAndStake,
  formatFromWei,
  getContract,
} from "../../web3/helpers";
import { tokenAddress } from "../../web3/contracts/token";
import { useParams } from "react-router-dom";
// import toast from "react-hot-toast";
const Stake = () => {
  const [value, setValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [balance, setBalance] = useState(0);
  const [approved, setApproved] = useState(false);
  const [stackValue, setStackValue] = useState(0);
  const [raritesToApply, setRaritesToApply] = useState(0);
  const [allPoolsDetails, setAllPoolsDetails] = useState([]);
  const [maxStakableTokensPerNFT, setMaxTokensPerNetwork] = useState(0);
  const { account, library } = useWeb3React();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setCurrentValue(Number(id));
    }
  }, [id]);
  useEffect(() => {
    const getDeatilsStaking = async () => {
      if (account) {
        const { ok, contract } = await getContract(library);
        if (!ok) {
          return;
        }

        try {
          // const allPoolsAPY = await contract.methods
          //   .getAllPoolsAPY()
          //   .call({ from: account });
          // setAllPoolAPY(allPoolsAPY);

          const _raritesToApply = await contract.methods
            .getRarityToApply()
            .call({ from: account });

          setRaritesToApply(_raritesToApply);
          // console.log(_raritesToApply);

          // const poolIds = await contract.methods
          //   .getStakePoolIds(tokenAddress)
          //   .call();
          // // console.log("poolIds", poolIds);
          // setPoolIds(poolIds);

          const allPoolsDetails = await contract.methods
            .getAllPoolDetails()
            .call();
          setAllPoolsDetails(allPoolsDetails);
          // console.log("POOLS", allPoolsDetails);

          const maxStakableTokensPerNFT = await contract.methods
            .getMaxStakableTokens()
            .call({ from: account });
          setMaxTokensPerNetwork(formatFromWei(maxStakableTokensPerNFT, 9));
        } catch (error) {
          console.log(error);
        }
      }
    };
    getDeatilsStaking();
  }, [account, library]);

  const stakeFlexiDetails = {
    id: allPoolsDetails.length ? allPoolsDetails[0]["id"] : "",
    days: allPoolsDetails.length ? allPoolsDetails[0]["duration"] : "",
    apr: allPoolsDetails.length ? allPoolsDetails[0][3] : "",
    poolWeight: "20",
  };
  // setFlexiDetails(stakeFlexiDetails);
  const stakeLockedDetails = [
    {
      id: allPoolsDetails.length ? allPoolsDetails[1]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[1]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[1][3] : "",
      poolWeight: "30",
    },
    {
      id: allPoolsDetails.length ? allPoolsDetails[2]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[2]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[2][3] : "",
      poolWeight: "40",
    },
    {
      id: allPoolsDetails.length ? allPoolsDetails[3]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[3]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[3]["APY"] : 0,
      poolWeight: "50",
    },
    {
      id: allPoolsDetails.length ? allPoolsDetails[4]["id"] : "",
      days: allPoolsDetails.length ? allPoolsDetails[4]["duration"] : "",
      apr: allPoolsDetails.length ? allPoolsDetails[4]["APY"] : 0,
      poolWeight: "50",
    },
  ];

  // STAKING BUTTON HANDLER 👇
  const stakingBtnHandler = (stakeId) => {
    approveAndStake(library, stackValue, stakeId);
  };
  // STAKING BUTTON HANDLER 👆

  // GET BALANCE AND CHECK APPROVAL 👇
  useEffect(() => {
    const getDetail = async () => {
      try {
        if (account) {
          const { ok, contract: tokenContract } = await getContract(
            library,
            tokenAddress
          );
          if (!ok) {
            return;
          }

          const data = await tokenContract.methods.balanceOf(account).call();
          //   setDetails(data[2]);
          const convertedData = Web3.utils.fromWei(data, "Gwei");
          // console.log(convertedData);
          setBalance(convertedData);
          const allowance = await tokenContract.methods
            .allowance(account, contractAddress)
            .call();
          if (Number(allowance) > 0) {
            // console.log("approved");
            setApproved(true);
          } else {
            // console.log("not approved");
            setApproved(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetail();
  }, [account, library]);
  // GET BALANCE AND CHECK APPROVAL 👆

  const inputHandler = (e) => {
    setStackValue(e.target.value);
  };

  return (
    <div>
      <div className="container">
        <div className="max-w-xl w-full mx-auto mb-2 text-lg text-center">
          You can stake maximum {maxStakableTokensPerNFT} FBURN tokens based on
          your NFT holdings.
          <br />
          <a
            href="https://burndapp.com/"
            target={"_blank"}
            className="inline-block mr-1 underline"
            rel="noreferrer"
          >
            Buy FireFOX NFT
          </a>
          {/* <a
            href="/"
            targtet="_blank"
            className="bg-primary font-bold text-xl py-2 px-8 w-full rounded-full mb-4 mx-auto block max-w-max"
          >
            Buy{" "}
          </a> */}
        </div>
        <div
          className=" max-w-xl w-full mx-auto  bg-transparent  border-gray-500 border rounded-lg  p-6  shadow-xl hover:shadow-2xl transition-all duration-300"
          // ref={modalRef}
        >
          <h1 className=" text-left font-bold text-xl md:text-3xl mt-0">
            Stake FBURN
          </h1>

          <div className=" grid grid-cols-2 mt-5">
            {["Flexi Pool", "Locked Pool"].map((v, i) => (
              <button
                className={`${
                  currentValue === i ? "bg-primary" : "bg-transparent"
                } border-b-2 border-primary py-2 md:py-4 font-semibold text-sm md:text-xl transition-all`}
                key={i}
                onClick={() => setCurrentValue(i)}
              >
                {v}
              </button>
            ))}
          </div>
          {currentValue === 0 ? (
            <Flexible
              handler={stakingBtnHandler}
              balance={balance}
              stackValue={stackValue}
              inputHandler={inputHandler}
              approved={approved}
              poolData={stakeFlexiDetails}
              setStackValue={setStackValue}
              raritesToApply={raritesToApply}
            />
          ) : (
            <Locked
              handler={stakingBtnHandler}
              value={value}
              setValue={setValue}
              balance={balance}
              stackValue={stackValue}
              inputHandler={inputHandler}
              approved={approved}
              poolData={stakeLockedDetails}
              setStackValue={setStackValue}
              raritesToApply={raritesToApply}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Stake;
