import StackInput from "./StackInput";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
const { Handle } = Slider;

const Locked = ({
  handler,
  value = 0,
  setValue,
  balance,
  stackValue,
  inputHandler,
  approved,
  poolData,
  setStackValue,
  raritesToApply,
}) => {
  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    setValue(value);
    // console.log(value - 1);
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value}`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  // console.log(poolData[count]?.days);
  const apyList = [
    {
      1: "360",
      2: "450",
      3: "600",
      4: "750",
    },
    {
      1: "540",
      2: "675",
      3: "900",
      4: "1125",
    },
    {
      1: "1080",
      2: "1350",
      3: "1800",
      4: "2250",
    },
    {
      1: "1800",
      2: "2250",
      3: "3000",
      4: "3750",
    },
  ];

  const rarity = Number(raritesToApply) ? Number(raritesToApply) - 1 : 0;
  const count = value - 1;
  const days = poolData[count]?.days;
  const weight = poolData[count]?.poolWeight;
  console.log(raritesToApply);
  const apr = apyList[rarity][value];

  const stakeId = poolData[count]?.id;
  // console.log(stakeId);
  return (
    <>
      <div className="mb-4 mt-10">
        <div className="flex justify-between items-center mb-4 md:mb-6">
          <p>
            Lock For: <span className="font-medium">{days} Days</span>
          </p>
          <p>
            Rarity Applied :{" "}
            <span className="font-medium">
              {Number(raritesToApply) === 1
                ? "Common"
                : Number(raritesToApply) === 2
                ? "Uncommon"
                : Number(raritesToApply) === 3
                ? "Rare"
                : Number(raritesToApply) === 4
                ? "Legendary"
                : "None"}
            </span>
          </p>
        </div>
        <Slider min={1} max={4} defaultValue={0} handle={handle} />
      </div>
      <p className="mt-4 mb-4 font-semibold text-lg">
        {" "}
        Balance: {Number(balance).toFixed(2)} FBURN
      </p>
      <StackInput
        stackValue={stackValue}
        inputHandler={inputHandler}
        setStackValue={setStackValue}
        balance={balance}
      />
      <p className="text-left text-base my-4">Est. APR: {apr}%</p>
      {/* <div className="p-2 md:py-4  px-4 bg-dd3 text-red rounded-xl text-base font-bold">
        <p className="md:mx-10 text-center leading-3">
          Be aware that there are always risks associated with staking
          contracts. You assume all responsibility.
        </p>
      </div> */}
      <div className="mt-6 md:mt-10 primary-btn">
        <button
          className="bg-primary font-bold text-xl py-2 px-8 w-full rounded-full"
          onClick={() => handler(stakeId)}
        >
          {approved ? "Stake" : "Approve"}
        </button>
      </div>
    </>
  );
};

export default Locked;
