import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  p: 4,
};

export default function LockedWarningModal({ open, setOpen, handleUnstake }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={style}>
          <div className=" bg-dark-900 p-6 py-10 rounded-lg text-center">
            <h2 className="font-bold text-2xl">Warning</h2>
            <p className="text-base mt-2 mb-4">
              Unstaking before the timer expires will result in a 80% loss on
              your accumulated rewards.
            </p>
            <div className="grid grid-flow-col justify-center items-center gap-4  mt-6">
              <button
                onClick={handleClose}
                className="bg-gray-700 py-2 px-6  rounded-xl  "
              >
                Cancel
              </button>
              <button
                onClick={handleUnstake}
                className="bg-red-600 py-2 px-6  rounded-xl  "
              >
                Unstake
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
