import toast from "react-hot-toast";
import Web3 from "web3";
import { contractAbi, contractAddress } from "../contracts/primary";
import { tokenAbi, tokenAddress } from "../contracts/token";

export const shortAddress = (str) => {
  if (str) {
    if (str.length < 10) return str;
    return `${str.slice(0, 4)}`;
  } else {
    return "";
  }
};

export const toHex = (num) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

export const formatFromWei = (str, decimal) => {
  if (str) {
    if (str.length < 1) return str;
    if (decimal === 9) {
      return Number(Web3.utils.fromWei(str, "Gwei")).toFixed(2);
    }
    return Web3.utils.fromWei(str, "ether");
  }
};
export const formatToWei = (str, decimal) => {
  if (str) {
    if (str.length < 1) return str;
    if (decimal === 9) {
      return Web3.utils.toWei(str, "Gwei");
    }
    return Web3.utils.toWei(str, "ether");
  }
};

export const getContract = async (library, token) => {
  try {
    let _contract = null;
    if (token) {
      _contract = await new library.eth.Contract(tokenAbi, token);
    } else {
      _contract = await new library.eth.Contract(contractAbi, contractAddress);
    }
    return { ok: true, contract: _contract };
  } catch (error) {
    console.log(error);
    return { ok: false, contract: null };
  }
};

export const getUserBalance = async (library, account) => {
  try {
    let balance = await library.eth.getBalance(account);
    balance = formatFromWei(balance);

    return Number(balance).toFixed(4);
  } catch (error) {
    console.log(error);
    return 0;
  }
};
export const approveAndStake = async (library, input, stakeId) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    tokenAddress
  );
  const { ok: contractOK, contract } = await getContract(library);
  const accounts = await library.eth.getAccounts();

  if (!ok || !contractOK) {
    toast.error("Something went wrong, please try again later");
    return;
  }

  const nfts = await contract.methods.getNFTs().call({ from: accounts[0] });
  const userDetails = await contract.methods.getUserDetails(accounts[0]).call();
  const stakedBalance = formatFromWei(userDetails.totalStakedBalance, 9);
  const stakeAbleValue = nfts.length * 25 - Number(stakedBalance);

  if (nfts.length === 0) {
    toast.error("You dont have nfts, You cant stake");
    return;
  }

  if (!input) {
    toast.error(`Invalid Input`);
    return;
  } else if (stakeAbleValue === 0) {
    toast.error(`You staked your Limited amount of tokens `);
    return;
  } else if (input > stakeAbleValue) {
    toast.error(`You can stake only ${stakeAbleValue} Tokens`);
    return;
  }
  const toastId = toast.loading("Please Wait Transaction is processing");

  let balance = await tokenContract.methods.balanceOf(accounts[0]).call();
  balance = formatFromWei(balance, 9);
  // console.log("BALANCE", balance);

  if (+balance < input || +balance === 0) {
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }
  const allowance = await tokenContract.methods
    .allowance(accounts[0], contractAddress)
    .call();
  const totalSupply = await tokenContract.methods.totalSupply().call();
  console.log(allowance, totalSupply);
  const value = formatToWei(input, 9);
  // return;
  if (+allowance > 0) {
    try {
      contract.methods
        .stake(stakeId, value)
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success("Transaction Completed Successfully", { id: toastId });

          setTimeout(() => {
            window.location.href = "/rewards";
            // window.location.reload(false);
          }, 1000);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", { id: toastId });
    }
    return;
  }

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  try {
    tokenContract.methods
      .approve(contractAddress, totalSupply)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.loading("Please confirm transaction to deposit", { id: toastId });

        contract.methods
          .stake(stakeId, value)
          .send({
            from: accounts[0],
          })
          .on("transactionHash", function () {
            toast.loading(
              "Please wait to get confirmation of the transaction from blockchain",
              { id: toastId }
            );
          })
          .on("confirmation", function (receipt) {
            console.log("CONFIRMATION", receipt);
          })
          .on("receipt", async function (receipt) {
            toast.success("Transaction Completed Successfully", {
              id: toastId,
            });

            setTimeout(() => {
              window.location.href = "/rewards";
              // window.location.reload(false);
            }, 1000);
          })
          .on("error", function (error, receipt) {
            if (error.code === 4001) {
              toast.error("Transaction Rejected", { id: toastId });
            } else {
              toast.error("Transaction Failed", { id: toastId });
            }
          });
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    toast.error("Something went wrong!", { id: toastId });
  }
};

export const claim = async (library, stakeId) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });

  contract.methods
    .claimRewards(stakeId)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const unStake = async (library, stakeId) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });

  contract.methods
    .unstake(stakeId)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const reStake = async (library, stakeId) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });

  contract.methods
    .restake(stakeId)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const approveAndInject = async (library, input) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    tokenAddress
  );
  const { ok: contractOK, contract } = await getContract(library);
  const accounts = await library.eth.getAccounts();

  if (!ok || !contractOK) {
    toast.error("Something went wrong, please try again later");
    return;
  }

  // const nfts = await contract.methods.getNFTs().call({ from: accounts[0] });
  // const userDetails = await contract.methods.getUserDetails(accounts[0]).call();
  // const stakedBalance = formatFromWei(userDetails.totalStakedBalance, 9);
  // const stakeAbleValue = nfts.length * 50 - Number(stakedBalance);

  // if (nfts.length === 0) {
  //   toast.error("You dont have nfts, You cant stake");
  //   return;
  // }

  if (!input) {
    toast.error(`Invalid Input`);
    return;
  }
  // else if (stakeAbleValue === 0) {
  //   toast.error(`You staked your Limited amount of tokens `);
  //   return;
  // } else if (input > stakeAbleValue) {
  //   toast.error(`You can stake only ${stakeAbleValue} Tokens`);
  //   return;
  // }
  const toastId = toast.loading("Please Wait Transaction is processing");

  let balance = await tokenContract.methods.balanceOf(accounts[0]).call();
  balance = formatFromWei(balance, 9);
  // console.log("BALANCE", balance);

  if (+balance < input || +balance === 0) {
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }
  const allowance = await tokenContract.methods
    .allowance(accounts[0], contractAddress)
    .call();
  const totalSupply = await tokenContract.methods.totalSupply().call();
  console.log(allowance, totalSupply);
  const value = formatToWei(input, 9);
  // return;
  if (+allowance > 0) {
    try {
      contract.methods
        .injectRewardsSupply(value)
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success("Transaction Completed Successfully", { id: toastId });

          setTimeout(() => {
            window.location.href = "/rewards";
            // window.location.reload(false);
          }, 1000);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", { id: toastId });
    }
    return;
  }

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  try {
    tokenContract.methods
      .approve(contractAddress, totalSupply)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.loading("Please confirm transaction to deposit", { id: toastId });

        contract.methods
          .injectRewardsSupply(value)
          .send({
            from: accounts[0],
          })
          .on("transactionHash", function () {
            toast.loading(
              "Please wait to get confirmation of the transaction from blockchain",
              { id: toastId }
            );
          })
          .on("confirmation", function (receipt) {
            console.log("CONFIRMATION", receipt);
          })
          .on("receipt", async function (receipt) {
            toast.success("Transaction Completed Successfully", {
              id: toastId,
            });

            setTimeout(() => {
              window.location.href = "/rewards";
              // window.location.reload(false);
            }, 1000);
          })
          .on("error", function (error, receipt) {
            if (error.code === 4001) {
              toast.error("Transaction Rejected", { id: toastId });
            } else {
              toast.error("Transaction Failed", { id: toastId });
            }
          });
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    toast.error("Something went wrong!", { id: toastId });
  }
};
