// export const contractAddress = "0xDC1Ca21d3F6B7B29290349ddf121982C960f5863"; //TESTNET
export const contractAddress = "0xFeaA590d408CD0E6bB31BB562130c3aEC8dbD1d1"; //MAINNET
export const contractAbi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_duration", type: "uint256" },
      { internalType: "uint256", name: "_withdrawalFee", type: "uint256" },
      { internalType: "uint256", name: "_unstakePenalty", type: "uint256" },
    ],
    name: "addStakePool",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "apys",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseTokenAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "claimRewards",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_rarity", type: "uint256" },
      { internalType: "uint256", name: "_lockDuration", type: "uint256" },
    ],
    name: "getAPY",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllPoolDetails",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
          { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
          { internalType: "uint256", name: "unstakeFee", type: "uint256" },
          {
            internalType: "uint256",
            name: "earlyUnstakePenalty",
            type: "uint256",
          },
          { internalType: "uint256", name: "stakedTokens", type: "uint256" },
          { internalType: "uint256", name: "claimedRewards", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct fBurnStake.stakePool[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_rarity", type: "uint256" },
      { internalType: "uint256", name: "_lockDuration", type: "uint256" },
    ],
    name: "getDPR",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getElapsedTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getHoldingNFTRarities",
    outputs: [{ internalType: "uint256[4]", name: "", type: "uint256[4]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getMaxStakableTokens",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getNFTs",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getRarityToApply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_stakePoolId", type: "uint256" },
    ],
    name: "getStakePoolDetailsById",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "duration", type: "uint256" },
          { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
          { internalType: "uint256", name: "unstakeFee", type: "uint256" },
          {
            internalType: "uint256",
            name: "earlyUnstakePenalty",
            type: "uint256",
          },
          { internalType: "uint256", name: "stakedTokens", type: "uint256" },
          { internalType: "uint256", name: "claimedRewards", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct fBurnStake.stakePool",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getStakePoolIdByStakeId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getTotalUnclaimedRewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUnclaimedRewards",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUserAllStakeDetails",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "stakePoolId", type: "uint256" },
          { internalType: "uint256", name: "stakeBalance", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
          { internalType: "uint256", name: "lockedTime", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "lockDuration", type: "uint256" },
        ],
        internalType: "struct fBurnStake.userStake[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getUserAllStakeDetailsByAddress",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          { internalType: "uint256", name: "stakePoolId", type: "uint256" },
          { internalType: "uint256", name: "stakeBalance", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
          { internalType: "uint256", name: "status", type: "uint256" },
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
          { internalType: "uint256", name: "lockedTime", type: "uint256" },
          { internalType: "uint256", name: "unlockTime", type: "uint256" },
          { internalType: "uint256", name: "lockDuration", type: "uint256" },
        ],
        internalType: "struct fBurnStake.userStake[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getUserDetails",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "id", type: "uint256" },
          {
            internalType: "uint256",
            name: "totalStakedBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalClaimedRewards",
            type: "uint256",
          },
          { internalType: "uint256", name: "createdTime", type: "uint256" },
        ],
        internalType: "struct fBurnStake.user",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUserStakeBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUserStakeIds",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_userAddress", type: "address" },
    ],
    name: "getUserStakeIdsByAddress",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "getUserStakeOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "injectRewardsSupply",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isPaused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "isStakeLocked",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "magnitude",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxStakableTokensPerNFT",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "nftRarities",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bool", name: "_pauseStatus", type: "bool" }],
    name: "pauseStake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "poolIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "restake",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardTokensAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_maxStakableTokensPerNFT",
        type: "uint256",
      },
    ],
    name: "setMaxStakableTokensPerNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "stake",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stakeIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "stakePoolArray",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "duration", type: "uint256" },
      { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
      { internalType: "uint256", name: "unstakeFee", type: "uint256" },
      { internalType: "uint256", name: "earlyUnstakePenalty", type: "uint256" },
      { internalType: "uint256", name: "stakedTokens", type: "uint256" },
      { internalType: "uint256", name: "claimedRewards", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "stakePoolsById",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "duration", type: "uint256" },
      { internalType: "uint256", name: "withdrawalFee", type: "uint256" },
      { internalType: "uint256", name: "unstakeFee", type: "uint256" },
      { internalType: "uint256", name: "earlyUnstakePenalty", type: "uint256" },
      { internalType: "uint256", name: "stakedTokens", type: "uint256" },
      { internalType: "uint256", name: "claimedRewards", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalClaimedBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalInjectedRewardsSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalStakedBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_stakeId", type: "uint256" }],
    name: "unstake",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
      { internalType: "uint256[]", name: "_rarities", type: "uint256[]" },
    ],
    name: "updateBulkNFTRarity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_tokenId", type: "uint256" },
      { internalType: "uint256", name: "_rarity", type: "uint256" },
    ],
    name: "updateNFTRarity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "userIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "userStakeArray",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "stakeBalance", type: "uint256" },
      { internalType: "uint256", name: "totalClaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
      { internalType: "uint256", name: "lockedTime", type: "uint256" },
      { internalType: "uint256", name: "unlockTime", type: "uint256" },
      { internalType: "uint256", name: "lockDuration", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "userStakeIds",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "userStakeLists",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "stakeBalance", type: "uint256" },
      { internalType: "uint256", name: "totalClaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
      { internalType: "uint256", name: "lockedTime", type: "uint256" },
      { internalType: "uint256", name: "unlockTime", type: "uint256" },
      { internalType: "uint256", name: "lockDuration", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "userStakesById",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "stakePoolId", type: "uint256" },
      { internalType: "uint256", name: "stakeBalance", type: "uint256" },
      { internalType: "uint256", name: "totalClaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "lastClaimedTime", type: "uint256" },
      { internalType: "uint256", name: "status", type: "uint256" },
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
      { internalType: "uint256", name: "lockedTime", type: "uint256" },
      { internalType: "uint256", name: "unlockTime", type: "uint256" },
      { internalType: "uint256", name: "lockDuration", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "users",
    outputs: [
      { internalType: "uint256", name: "id", type: "uint256" },
      { internalType: "uint256", name: "totalStakedBalance", type: "uint256" },
      { internalType: "uint256", name: "totalClaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "createdTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawContractETH",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "withdrawInjectedRewardSupply",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];
