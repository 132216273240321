import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { approveAndInject } from "../web3/helpers";

const Inject = () => {
  const [tokens, setTokens] = useState(0);
  const { library } = useWeb3React();
  return (
    <div>
      <div className=" max-w-sm mx-auto w-full p-4 mt-10">
        <p className="text-3xl uppercase font-bold">Inject Token</p>
        <input
          type="number"
          className="py-2 px-4 w-full mt-3 focus:outline-none text-black text-xl rounded-xl"
          onChange={(e) => setTokens(e.target.value)}
        />
        <button
          onClick={() => approveAndInject(library, tokens)}
          className="px-6 py-2 rounded-lg bg-primary mt-4 block mx-auto "
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Inject;
